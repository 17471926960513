<script>
  import Button, { Label as ButtonLabel } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import CircularProgress from "@smui/circular-progress";
  import Dialog, { Content, Header, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import IconButton, { Icon } from "@smui/icon-button";
  import List, { Graphic, Item } from "@smui/list";
  import Radio from "@smui/radio";
  import Select, { Option } from "@smui/select";
  import Switch from "@smui/switch";
  import Tab, { Label } from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import Textfield from "@smui/textfield";
  import HelperText from "@smui/textfield/helper-text";
  import { format as formatDate } from "date-fns";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import BackCameraIcon from "~/components/icons/backCamera.svelte";
  import FrontCameraIcon from "~/components/icons/frontCamera.svelte";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    NotificationCategory,
    REMIND_HOURS,
    REMIND_MINUTES,
  } from "~/libs/constants";
  import geolocator from "~/libs/geolocator";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import reminder from "~/libs/reminder";
  import { reserveSyncCurrentLocation } from "~/libs/syncOperationState";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {boolean} 設定ダイアログの開閉フラグ */
  let dialogOpend = false;

  /** @type {Array<MediaDeviceInfo>} */
  let frontCameras = [];
  /** @type {Array<MediaDeviceInfo>} */
  let backCameras = [];
  /** @type {Array<{label: string, icon: object}>} */
  let cameraSelectionTabs = [];
  /** @type {object} */
  let activeCameraSelectionTab;

  /** @type {boolean} QRコードの読み取りで高解像度モードを使用するか否か */
  let useOpenCvQrCodeScanner = appContext.useOpenCvQrCodeScanner !== false;
  /** @type {boolean} カメラを手動で指定するか否か */
  let useManualCameraSelection = appContext.useManualCameraSelection === true;
  /** @type {string} 手動で指定したフロントカメラのID */
  let selectedFrontCameraId = appContext.selectedFrontCameraId;
  /** @type {string} 手動で指定したバックカメラのID */
  let selectedBackCameraId = appContext.selectedBackCameraId;

  /** @type {string} */
  let dummyPositionInputText = "";

  let timeForTestInputText =
    reminder.timeForTest ?? REMIND_HOURS + ":" + REMIND_MINUTES;

  let dateForTestInputText = formatDate(new Date(), "yyyy-MM-dd");
  let notificationCategory = NotificationCategory.INFO;

  $: if (selectedFrontCameraId || selectedBackCameraId) {
    appContext.selectedFrontCameraId = selectedFrontCameraId;
    appContext.selectedBackCameraId = selectedBackCameraId;
    appContext.store();
  }

  /**
   * ダイアログを開く。
   */
  export function openDialog() {
    dialogOpend = true;
    if (useManualCameraSelection) {
      onEnableManualCameraSelection();
    }

    dummyPositionInputText = geolocator.dummyPosition
      ? `${geolocator.dummyPosition.coords.latitude}, ${geolocator.dummyPosition.coords.longitude}`
      : "";
  }

  async function onEnableManualCameraSelection() {
    if (frontCameras.length > 0 || backCameras.length > 0) {
      return;
    }

    try {
      // request permission
      (
        await navigator.mediaDevices.getUserMedia({
          video: true,
        })
      )
        .getTracks()
        .forEach((track) => {
          track.stop();
        });

      // get camera devices
      const cameraDevices = (
        await navigator.mediaDevices.enumerateDevices()
      ).filter((device) => device.kind === "videoinput");

      // classify camera devices to front camera and back camera
      [frontCameras, backCameras] = cameraDevices.reduce(
        (accumulator, device) => {
          const lowerLabel = device.label.toLowerCase();
          if (
            lowerLabel.indexOf("前面") != -1 ||
            lowerLabel.indexOf("front") != -1
          ) {
            accumulator[0].push(device);
          } else {
            accumulator[1].push(device);
          }
          return accumulator;
        },
        [[], []],
      );
    } catch (error) {
      console.error(`${error.name}: ${error.message}`); // use non-logger explicitly
      toast.error($_("errors.failedToAccessCamera") + error.message, {
        popsWhenPageMoved: true,
      });
      return;
    }

    const tabs = [];
    if (frontCameras.length >= 2) {
      tabs.push({ label: "前面カメラ", icon: FrontCameraIcon });
    }
    if (backCameras.length >= 2) {
      tabs.push({ label: "背面カメラ", icon: BackCameraIcon });
    }
    cameraSelectionTabs = tabs;
    activeCameraSelectionTab = tabs.length > 0 ? tabs[0] : undefined;
  }
</script>

{#if dialogOpend}
  <div class="configurationDialog">
    <Dialog bind:open={dialogOpend}>
      <Header>
        <Title>アプリの設定</Title>
        <IconButton action="close" class="material-icons" ripple={false}
          >close</IconButton
        >
      </Header>
      <Content>
        <div class="category">
          <p class="categoryHeader">カメラ設定 (QRコード読み取り用)</p>

          <FormField>
            <Switch
              bind:checked={useOpenCvQrCodeScanner}
              on:SMUISwitch:change={(event) => {
                appContext.useOpenCvQrCodeScanner = event.detail.selected;
                appContext.store();
              }}
            />
            <span slot="label"
              >高精度モードを使用 <span style="font-size: 0.8em;"
                >*{useOpenCvQrCodeScanner
                  ? "要端末性能"
                  : "1次元バーコード(Codabar)は読取不可"}</span
              ></span
            >
          </FormField>

          <FormField>
            <Switch
              bind:checked={useManualCameraSelection}
              on:SMUISwitch:change={(event) => {
                appContext.useManualCameraSelection = event.detail.selected;
                if (event.detail.selected) {
                  onEnableManualCameraSelection();
                }
                appContext.store();
              }}
            />
            <span slot="label">使用するカメラを手動で指定</span>
          </FormField>

          {#if useManualCameraSelection}
            {#if frontCameras.length == 0 && backCameras.length == 0}
              <div style="display: flex; justify-content: center">
                <CircularProgress
                  indeterminate
                  style="height: 32px; width: 32px;"
                />
              </div>
            {:else if cameraSelectionTabs.length == 0}
              <p class="noNeedToSelectCameraMessage">
                選択可能なカメラがありません<br /><span
                  style="font-size: 0.8em;"
                  >(カメラが1種類しかない場合は表示されません)</span
                >
              </p>
            {:else}
              <TabBar
                tabs={cameraSelectionTabs}
                let:tab
                bind:active={activeCameraSelectionTab}
              >
                <Tab {tab}>
                  <Icon><svelte:component this={tab.icon} /></Icon>
                  <Label>{tab.label}</Label>
                </Tab>
              </TabBar>

              <List radioList>
                {#if activeCameraSelectionTab?.icon == FrontCameraIcon}
                  {#each frontCameras as frontCamera}
                    <Item>
                      <Graphic>
                        <Radio
                          bind:group={selectedFrontCameraId}
                          value={frontCamera.deviceId}
                        />
                      </Graphic>
                      <Label>{frontCamera.label}</Label>
                    </Item>
                  {/each}
                {/if}
                {#if activeCameraSelectionTab?.icon == BackCameraIcon}
                  {#each backCameras as backCamera}
                    <Item>
                      <Graphic>
                        <Radio
                          bind:group={selectedBackCameraId}
                          value={backCamera.deviceId}
                        />
                      </Graphic>
                      <Label>{backCamera.label}</Label>
                    </Item>
                  {/each}
                {/if}
              </List>
            {/if}
          {/if}

          <FormField>
            <Checkbox
              checked={appContext.enablesLowResolutionPodPhotoMode ?? false}
              on:change={(/** @type {Event} */ event) => {
                appContext.enablesLowResolutionPodPhotoMode =
                  /** @type {HTMLInputElement} */ (event.target).checked;
                appContext.store();
              }}
            />
            <span slot="label">低解像度で配達証明写真を撮影</span>
          </FormField>
        </div>

        {#if import.meta.env.MODE !== "production"}
          <div class="category">
            <p class="categoryHeader">その他</p>

            <div style="text-align: center; margin-top: 10px;">
              <Textfield
                variant="outlined"
                bind:value={dummyPositionInputText}
                label="ダミーGPS座標 (緯度, 経度)"
                style="width: 100%;"
                input$pattern="\s*-?\d+(?:\.\d+)?,\s*-?\d+(?:\.\d+)?\s*"
                on:change={(/** @type {object} */ event) => {
                  const dummyPositionHelperElement = document.getElementById(
                    "dummyPositionHelper",
                  );
                  if (event.target.value) {
                    const latlonArray = /** @type {HTMLInputElement} */ (
                      event.target
                    ).value
                      .trim()
                      .split(/,\s*/);
                    const latitude = parseFloat(latlonArray[0]);
                    const longitude = parseFloat(latlonArray[1]);
                    if (
                      !(
                        latitude >= -90.0 &&
                        latitude <= 90.0 &&
                        longitude >= -180.0 &&
                        longitude <= 180.0
                      )
                    ) {
                      dummyPositionHelperElement.style.display = "block";
                      return;
                    }

                    geolocator.dummyPosition = {
                      coords: {
                        latitude: latitude,
                        longitude: longitude,
                        accuracy: 0,
                        altitude: null,
                        altitudeAccuracy: null,
                        heading: null,
                        speed: null,
                        toJSON: () => undefined,
                      },
                      timestamp: Date.now(),
                      toJSON: () => undefined,
                    };
                  } else {
                    geolocator.dummyPosition = undefined;
                    geolocator.requestCurrentPosition(false);
                  }

                  dummyPositionHelperElement.style.display = "none";
                }}
              >
                <HelperText
                  persistent
                  slot="helper"
                  id="dummyPositionHelper"
                  style="display: none; color: var(--mdc-theme-error);"
                  >※緯度は-90～90、経度は-180～180の範囲</HelperText
                >
              </Textfield>
            </div>

            <div
              style="display: flex; justify-content: center; margin-top: 10px;"
            >
              <Button
                color="secondary"
                variant="unelevated"
                style="width: 100%; height: 56px;"
                on:click={() => {
                  reserveSyncCurrentLocation(userContext);
                }}
              >
                <ButtonLabel>業務従事状況(位置情報)の強制同期</ButtonLabel>
              </Button>
            </div>
          </div>

          <div
            style="display: flex; justify-content: space-evenly; margin-top: 10px;"
          >
            <Textfield
              style="width: 130px;"
              type="time"
              variant="outlined"
              label="リマインド時間"
              bind:value={timeForTestInputText}
              on:change={(/** @type {object} */ event) => {
                if (event.target.value) {
                  reminder.timeForTest = event.target.value;
                } else {
                  reminder.timeForTest = undefined;
                }
              }}
            />
            <Button
              style="width: 150px; height: 56px;"
              variant="outlined"
              on:click={() => {
                userContext.timeOfShowingDeliveryRemind = undefined;
                userContext.store();
              }}
            >
              <ButtonLabel>時間指定便の<br />通知実績初期化</ButtonLabel>
            </Button>
          </div>

          <div
            style="display: flex; justify-content: center; margin-top: 10px;"
          >
            <Textfield
              style="width: 120px;"
              type="date"
              variant="outlined"
              bind:value={dateForTestInputText}
            />
            <Select bind:value={notificationCategory} style="width: 100px;">
              <Option value={NotificationCategory.INFO}>INFO</Option>
              <Option value={NotificationCategory.ERROR}>エラー</Option>
              <Option value={NotificationCategory.PUSH}>通知</Option>
            </Select>
            <Button
              style="height: 56px; width: 50px;"
              on:click={() => {
                notificationHistoryUtils.addHistory(
                  userContext.loginUser.username,
                  dateForTestInputText.replace(/-/g, ""),
                  formatDate(new Date(), "HHmmss"),
                  notificationCategory,
                  "検証用メッセージです。",
                );
                toast.info("通知履歴に検証用メッセージを追加しました。", {
                  popsWhenPageMoved: true,
                });
              }}
            >
              <ButtonLabel>追加</ButtonLabel>
            </Button>
          </div>
        {/if}
      </Content>
    </Dialog>
  </div>
{/if}

<style lang="scss">
  .configurationDialog {
    :global(.mdc-dialog) {
      z-index: 201;
    }

    :global(.mdc-dialog__header .mdc-icon-button) {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  .category {
    margin-top: 5px;
    padding: 10px 0;
    border-top: solid 1px #ccc;

    :global(.mdc-tab-bar) {
      margin: 5px 0 5px 0;
    }

    :global(.svg-icons-front-camera),
    :global(.svg-icons-back-camera) {
      width: 19px;
      height: 24px;
      vertical-align: super;
    }

    :global(mdc-deprecated-list) {
      padding-bottom: 0;
    }
  }

  .categoryHeader {
    margin-bottom: 5px;
  }

  .noNeedToSelectCameraMessage {
    margin-top: 8px;
    background-color: #ffe0e0;
    border-radius: 5px;
    text-align: center;
    font-size: 0.9em;
    color: #bd362f;
  }
</style>
